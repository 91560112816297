import Head from "next/head";
import { Poppins, Roboto } from "next/font/google";
import { useContext, Suspense, useState, useEffect } from "react";
import Login from "./auth/login";
import AuthContext from "./api/auth-context";
import Dashboard from "./dashboard/dashboard";

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

const roboto = Roboto({
  subsets: ["latin"],
  weight: ["100", "300", "400", "500", "700", "900"],
});

export default function Home() {
  const { isAuthenticated } = useContext(AuthContext);
  const [domLoaded, setDomLoaded] = useState(false);
  
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
    {domLoaded && (
    <Suspense>
      <Head>
        <title>Autorola - TSB</title>

        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <main className={poppins.className}>
        {isAuthenticated ? <Dashboard /> : <Login />}
      </main>
      </Suspense>
      )}
    </>
  );
}
