import axios from "axios";
import Link from 'next/link';
import { useContext, useEffect, useState } from "react";
import AuthContext from "../api/auth-context";
import { Unstable_Grid2, Typography, Button, Stack } from "@mui/material";

export default function QueryCount() {
  const { token } = useContext(AuthContext);

  const [queryCount, setQueryCount] = useState([]);
  useEffect(() => {
    const options = {
      method: "GET",
      url: process.env.RESTURL + "useractions",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        setQueryCount(response.data);
        console.log('query details', response.data)
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [token]);

  return (
    <Unstable_Grid2 xs={4}>
      <Unstable_Grid2 display='flex' direction='row'>
      <Unstable_Grid2 sx={{ pr: 2 }}>
        <Link style={{color: 'unset', textDecoration: 'unset'}} href='/users/user-actions'>
          <Button
            sx={{ height: "100%", width: "auto", textTransform: 'none' }}
            variant='contained'
            color='primary'
          >
            Görüntüle
          </Button>
          </ Link>
        </Unstable_Grid2>
        <Unstable_Grid2>
          <Stack direction='column'>
            <Typography variant="h6">Query Sayısı</Typography>
            <Typography variant="caption">Son 7 Gündeki Query Sayısı</Typography>
            {/* <Typography variant="body1">{queryCount.length}</Typography> */}
          </Stack>
        </Unstable_Grid2>
      </Unstable_Grid2>
    </Unstable_Grid2>
  );
}
