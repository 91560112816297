import * as React from "react";
import { Unstable_Grid2 } from "@mui/material";
import QueryCount from "./query-count";
import UserCount from "./user-count";
import VehicleCount from "./vehicle-count";
import Layout from "../layout";

export default function Dashboard() {
  return (
    <Layout>
      <Unstable_Grid2
        container
        sx={{ mt: 2 }}
        justifyContent='center'
        spacing={2}
      >
        <UserCount />
        <QueryCount />
        <VehicleCount />
      </Unstable_Grid2>
    </Layout>
  );
}
